<template>
  <PageContent
    class="fiche-devis-commande"
    :nav-titles="navTitles"
    :page-title="objectInfos && objectInfos.libelle"
  >
    <template #header-right>
      <ButtonClassic
        :variant="onlyNotDeliveredNotCancelled ? 'solid' : 'ghost'"
        color="primary"
        icon="right"
        :label="$t('commande.voire-restant-a-livrer')"
        :disabled="disableRestant"
        @click="onlyNotDeliveredNotCancelled = !onlyNotDeliveredNotCancelled"
      >
        <template #right-icon>
          <UilTimes v-if="onlyNotDeliveredNotCancelled" />
          <UilFilter v-else />
        </template>
      </ButtonClassic>
      <ButtonGroup>
        <ButtonClassic
          v-matomo-log-click="['commande_pdf', ]"
          balise-type="a"
          variant="solid"
          icon="right"
          :ext-link="urlPdf"
        >
          <template #right-icon>
            <IconPDF />
          </template>
        </ButtonClassic>
        <ButtonClassic
          v-matomo-log-click="['commande_clipboard', ]"
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="!isLoading && objectInfos"
      #action-bar
    >
      <div>
        <div class="flex-vcenter">
          <div
            v-if="objectInfos.nom_organisme"
            class="flex-vcenter organisme-details"
          >
            <IconEstablishment />
            <strong class="text-small name">
              {{ objectInfos.nom_organisme }}
            </strong>
          </div>
          <div
            v-if="objectInfos.budget
              && Object.keys(objectInfos.budget).length
              && (!isHorsMarche || objectInfos.budget.dotation > 0)"
            class="budget flex-vcenter"
          >
            <template v-if="budgetType === 'euros'">
              <UilEuroCircle
                size="16"
                :class="budgetStatus"
              />
              <span class="text-small blue-dark">
                {{ objectInfos.budget.enCours + objectInfos.budget.devisTransmis | moneyFilter }}{{ "\xa0" }}€/{{
                  objectInfos.budget.dotation | moneyFilter }}{{ "\xa0" }}€
              </span>
            </template>
            <template v-else-if="budgetType === 'licences'">
              <UilBooks
                size="16"
                :class="budgetStatus"
              />
              <span class="text-small blue-dark">
                {{ objectInfos.budget.enCours + objectInfos.budget.devisTransmis | spaceNumber }}/{{
                  objectInfos.budget.dotation | spaceNumber }}
              </span>
            </template>
          </div>
        </div>
        <div
          id="zone_buttons"
          class="budget flex-vcenter"
        >
          <div
            v-if="objectInfos.total_ttc !== null || objectInfos.total_ht !== null"
            class="total-prices"
          >
            <strong
              v-if="objectInfos.total_ttc !== objectInfos.total_ht"
              class="text-medium all-taxes"
              v-html="$t('prix.ttc', { prix: moneyFilter(objectInfos.total_ttc), })"
            />
            <span
              class="text-medium duty-free"
              v-html="$t('prix.ht', { prix: moneyFilter(objectInfos.total_ht), })"
            />
          </div>
          <InputClassic
            v-if="objectInfos.num_engagement"
            v-model="objectInfos.num_engagement"
            :label="$t('liste.n-engagement')"
            class="chorus-input-classic"
            type="text"
            inline
            disabled
          >
            <template #label-icon>
              <IconNumber />
            </template>
          </InputClassic>
          <ButtonStatusApprobation
            :label="primaryStatusLabel"
            :variant="statusButton"
          />
          <ContextMenu
            :bloc-actions-globales="actionsGlobales"
            @click-action="handleActions($event)"
          />
        </div>
      </div>
      <p
        v-if="hasNumerique"
        class="text-small italic no-refundable"
      >
        <UisExclamationTriangle />
        {{ $t('licence.licences-numeriques-ni-echangeables-ni-remboursables') }}
      </p>
    </template>
    <template #content>
      <template v-if="!isLoading && objectInfos">
        <BlocLignesCatalogues
          :object-infos="objectInfos"
          :catalogues="customCatalogues"
          is-commande
          disable-editing
          no-deletion-possible
          @sort="handleSort($event)"
        />
        <TableTotal
          :object-infos="objectInfos"
          :message-numerique="hasNumerique"
        />
        <PiecesJointes
          v-if="!objectInfos.verrouille"
          :attachments="attachments"
          @delete-attachment="deleteAttachment($event)"
        />
        <ListeActions
          v-if="!objectInfos.verrouille"
          context="commande"
          variant="group"
          :button-status="statusButton"
          :button-label="primaryStatusLabel"
        >
          <template #additional-buttons>
            <ButtonStatusApprobation
              v-if="objectInfos.user_can_cancel"
              :label="$t('commande.annuler-commande')"
              variant="secondary"
              large
              @click="$modal.show('modal_commande_confirm_cancel')"
            >
              <template #icon>
                <UilTimes />
              </template>
            </ButtonStatusApprobation>
          </template>
        </ListeActions>
        <ModalPiecesJointes
          :adoptant="isAdoptant"
          @confirm="fetchAttachments('commande')"
        />
      </template>
      <template v-else>
        <div>
          <InfiniteLoader />
        </div>
      </template>
      <ModalDatesLivraison
        :maitre-site="maitreSite"
        :rappel="rappel"
        @show-form="rappel = false"
      />
      <ModalCommandeConfirmCancel @confirm="cancelOrder()" />
      <ModalListesSelection
        id="modal_listes_selection"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
      />
    </template>
  </PageContent>
</template>

<script>
import { moneyFilter, copyToClipboard, InputClassic } from "@lde/core_lde_vue";

import ModalDatesLivraison from "@/components/modals/ModalDatesLivraison.vue";
import ModalCommandeConfirmCancel from "@/components/modals/ModalCommandeConfirmCancel.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";

import FicheDevisCommandes from "@/mixins/mixinFicheListeDevisCommandes";
import DuplicateInfos from "@/mixins/mixinDuplicateModal";

import { UilFilter, UilTimes, UilBookmark } from "@iconscout/vue-unicons";
import IconNumber from "@/components/icons/IconNumber.vue";
import IconPanier from "@/components/icons/IconPanier.vue";

import Api from "@/modules/axios";
import { changePageTitle, canAccessDesiderata } from "@/modules/utils";

import config from "@/config";

import { mapGetters } from "vuex";
import Moment from "moment";

/**
 * Vue d'une commande en particulier avec toutes ses informations.
 */
export default {
  name: "FicheCommande",
  components: {
    ModalDatesLivraison,
    ModalCommandeConfirmCancel,
    ModalListesSelection,
    UilFilter,
    UilTimes,
    InputClassic,
    IconNumber,
  },
  mixins: [FicheDevisCommandes, DuplicateInfos],
  data() {
    return {
      onlyNotDeliveredNotCancelled: false,
      maitreSite: null,
      rappel: true,
      controller: new AbortController(),
    };
  },
  computed: {
    ...mapGetters(["hasPerm", "user", "organismeActuel", "isHorsMarche", "activeLists"]),
    urlPdf() {
      return `${config.api.baseUrl}/commande/${this.$route.params.id}/pdf/`;
    },
    statusButton() {
      return this.objectInfos.statut_affiche === "annulee" ? "error" : "success";
    },
    actionsGlobales() {
      return [
        {
          title: this.$t("context-menu.actions-applicables-au-devis"),
          actions: [
            {
              slug: "add_to_list",
              label: this.$t("action.ajouter-references-liste"),
              icon: UilBookmark,
              disabled: this.isImpersonating || this.objectInfos.verrouille,
            },
            {
              slug: "add_to_panier",
              label: this.$t("action.ajouter-references-panier"),
              icon: IconPanier,
              disabled: this.isImpersonating || this.objectInfos.verrouille,
            },
          ],
        },
      ];
    },
    disableRestant() {
      const allDeliveredOrCancelled = this.catalogues.every((cat) => (
        cat.lignes?.every((ligne) => ligne.livre || [15, 20].includes(ligne.etat))
      ));

      const allNotDelivredNotCancelled = this.catalogues.every((cat) => (
        cat.lignes?.every((ligne) => !ligne.livre && ![15, 20].includes(ligne.etat))
      ));

      return allDeliveredOrCancelled || allNotDelivredNotCancelled;
    },
    customCatalogues() {
      if (this.onlyNotDeliveredNotCancelled) {
        return this.catalogues.map((cat) => ({
          ...cat,
          lignes: cat.lignes.filter((ligne) => !ligne.livre && ![15, 20].includes(ligne.etat)),
        }));
      }
      return this.catalogues;
    },
  },
  mounted() {
    this.fetchCommande();
    this.fetchAttachments("commande");

    this.fetchMaitreSite().then(() => {
      const aRemplir = !this.maitreSite.infos.date_demande_livraison;

      // On vérifie si on doit afficher la modale de rappel après avoir récupéré le maître site
      this.$nextTick(() => {
        const year = Moment().year();
        // Vu avec Rayane : le message de rappel dans les commandes s'affiche jusqu'au 15 août,
        // mais les dates sont modifiable jusqu'au 11 novembre.
        if (
          aRemplir
          && canAccessDesiderata(this.maitreSite)
          && Moment().isBetween(`${year}-06-03`, `${year}-08-15`, "days", "[]")
        ) {
          this.$modal.show("modal_dates_livraison");
        }
      });
    });
  },
  beforeDestroy() {
    this.controller.abort();
  },
  methods: {
    moneyFilter,
    copyToClipboard,
    /**
     * Récupère les informations de la commande.
     * @returns {Promise} Réponse après chargement de la commande.
     */
    fetchCommande() {
      this.isLoading = true;
      const id = this.$route.params.id;

      return Api().get(`/commande/${id}/`, { signal: this.controller.signal })
        .then((resCommande) => {
          if (this.controller.signal.aborted) {
            return false;
          }

          this.objectInfos = resCommande.data;

          const id4d = this.objectInfos.id_4d || this.$t("info.id-inconnu");
          const date = this.$moment(this.objectInfos.date).format(this.$t("global.format-date"));
          changePageTitle(`${this.objectInfos.libelle} - ${id4d} - ${date}`, this.$route);

          // Si on est région, on récupère les données de l'organisme
          if (this.hasPerm("can_devis_to_cmd")) {
            this.$store.dispatch("fetchBudgetOrganisme", this.objectInfos.organisme.id)
              .then((resBudget) => {
                this.budgetType = resBudget.type === "QUANTITE" ? "licences" : "euros";
                this.objectInfos = {
                  ...this.objectInfos,
                  nom_organisme: resBudget.nom,
                  budget: resBudget,
                };
                // this.isLoading = false;
              });
          }
          this.isLoading = false;

          // Pour tester
          // this.objectInfos.statut_affiche = "attente_selection";
          // this.objectInfos.statut_affiche = "attente_validation";
          // this.objectInfos.statut_affiche = "attente_commande";
          // this.objectInfos.statut_affiche = "attente_expedition";
          // this.objectInfos.statut_affiche = "refus";
          // this.objectInfos.statut_affiche = "annulee";

          if (this.objectInfos.statut_affiche !== "annulee") {
            this.objectInfos.totalExpediees = `${this.objectInfos.nb_expediee}/${this.objectInfos.total_quantite}`;
            this.objectInfos.totalLivrees = `${this.objectInfos.nb_livree}/${this.objectInfos.total_quantite}`;
          }

          this.objectInfos.lignes.forEach((ligne) => {
            // ligne.order_label = ligne.etat.libelle_client;
            ligne.order_label = ligne.etat_verbose;

            if (ligne.order_label === "Livré" && ligne.date_livraison) {
              ligne.order_label += ` ${ligne.date_livraison}`;
              ligne.order_status = "valid";
            }
          });

          return true;
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$store.commit("setErrorStatus", err.response.status);
          }
        });
    },
    /**
     * Annule une commande si c'est encore possible.
     */
    cancelOrder() {
      this.isLoading = true;
      const id = this.$route.params.id;
      Api().post(`/commande/${id}/cancel/`)
        .then(() => {
          this.fetchCommande();
        })
        .catch((error) => {
          this.$toast.error({ title: error.response.data });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * Gère les actions via leur slug.
     * @param {Object} action Action cliquée.
     */
    handleActions(action) {
      const modalName = this.activeLists.length ? "selection" : "creation";
      switch (action.slug) {
        case "add_to_panier":
          this.$store.dispatch("addOrderLinesToPanier", { commandes: [this.objectInfos.id] });
          break;
        case "add_to_list":
          this.$store.commit("setSelectedLines", [this.objectInfos.id]);
          this.$modal.show(`modal_listes_${modalName}`);
          break;
        default:
          break;
      }
    },
    /**
     * Récupère le maitre site de l'établissement de la fiche.
     * @returns {Promise} Maître site de l'organisme lié à la commande.
     */
    fetchMaitreSite() {
      return Api().get(`/organisme/${this.organismeActuel.infos.maitre_site || this.organismeActuel.id}/`)
        .then(({ data }) => {
          this.maitreSite = data;
        })
        .catch((err) => {
          // Si on n'a pas accès au maître site mais qu'il existe, on ne fait rien car c'est un cas possible
          if (err.response.status !== 404) {
            this.$toast.error({ title: err.response.data });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_fiche_devis_commande.scss";
</style>
